import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { OperationalWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';

export class DashboardWidgetHandler {
  getOperationalWidgetTitle(
    translation: any,
    type: OperationalWidgetAnalyticsType
  ) {
    return translation(`dashboards.widgets.${type}`);
  }

  getOperationalWidgets(translation: any) {
    return [
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.TOTAL_NEW_FINDINGS
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.BUG_ICON} />,
        type: OperationalWidgetAnalyticsType.TOTAL_NEW_FINDINGS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.ROOT_CAUSE
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.TELESCOPE_ICON} />,
        type: OperationalWidgetAnalyticsType.ROOT_CAUSE,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.AGGREGATED_RISK
        ),
        subTitle: '',
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.HEXAGON_EXCLAMATION_ICON} />,
        type: OperationalWidgetAnalyticsType.AGGREGATED_RISK,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.RESOLVED_WITHIN_SLA
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />,
        type: OperationalWidgetAnalyticsType.RESOLVED_WITHIN_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.NEW_WITHIN_SLA
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.BALLOT_CHECK_ICON} />,
        type: OperationalWidgetAnalyticsType.NEW_WITHIN_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.EXCEPTIONS_REQUESTS
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.FILE_EXCLAMATION_ICON} />,
        type: OperationalWidgetAnalyticsType.EXCEPTIONS_REQUESTS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.RESOLVED_FINDINGS
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_TO_SLOT_ICON} />,
        type: OperationalWidgetAnalyticsType.RESOLVED_FINDINGS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.SLA
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />,
        type: OperationalWidgetAnalyticsType.SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.AVERAGE_MTTR
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.TOOLS_ICON} />,
        type: OperationalWidgetAnalyticsType.AVERAGE_MTTR,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.RESOLVED_OUTSIDE_SLA
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_CHECK_ICON} />,
        type: OperationalWidgetAnalyticsType.RESOLVED_OUTSIDE_SLA,
      },

      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.NEW_OUTSIDE_SLA
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />,
        type: OperationalWidgetAnalyticsType.NEW_OUTSIDE_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalWidgetAnalyticsType.EXCEPTIONS_APPROVALS
        ),
        icon: <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_TO_SLOT_ICON} />,
        type: OperationalWidgetAnalyticsType.EXCEPTIONS_APPROVALS,
      },
    ];
  }
}
