import moment from 'moment';
import { FunctionComponent } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

const dashboardReportsDateFormat = 'MMM DD, YYYY HH:MM';

interface DashboardReportsHeaderProps extends BaseComponentProps {}

export const DashboardReportsHeader: FunctionComponent<
  DashboardReportsHeaderProps
> = () => {
  return (
    <div className="dashboard-reports-header">
      <div className="dashboard-reports-header-icon-container">
        <div className="dashboard-reports-header-icon">
          <img
            src="https://res.cloudinary.com/opus-security/image/upload/v1694180958/application-logos-svg/Opus.svg"
            alt="opus-logo"
          />
        </div>
        <div className="dashboard-reports-header-icon-text">opus</div>
      </div>

      <div className="dashboard-reports-header-date-container">
        {moment(new Date()).format(dashboardReportsDateFormat)}
      </div>
    </div>
  );
};
