import { FunctionComponent, useEffect, useState } from 'react';
import { DropdownPicker } from './DropdownPicker';
import { useFetchAvailableUsersToAssignMutation } from '../../../FindingDetails/store/api';
import { Typography } from '@mui/material';
import { AvailableUser } from '../../../FindingDetails/interfaces/Users';
import { useFetchUserQuery } from 'Auth/store/api';

interface PersonPickerProps {
  selectedPerson?: AvailableUser;
  readonly?: boolean;
  itemId?: string;
  property?: string;
  updateItemMutation?: any;
  onSelect?: (selectedUser: AvailableUser) => void;
  displayFullWidthPopover?: boolean;
  selectedUserId?: string;
  inputId?: string;
}

export const PersonPicker: FunctionComponent<PersonPickerProps> = ({
  selectedPerson,
  readonly = false,
  itemId,
  property,
  updateItemMutation,
  onSelect,
  displayFullWidthPopover,
  selectedUserId,
  inputId,
}) => {
  const { data: userData, isLoading: isLoadingUserData } = useFetchUserQuery(
    selectedUserId,
    { skip: !selectedUserId }
  );

  const [selected, setSelected] = useState(selectedPerson);

  const fetchAssignableUsersConfig = {
    limit: 0,
    offset: 0,
    sortBy: 'name',
    order: 'ASC',
  };
  const updateItemMutationPayload =
    !readonly && updateItemMutation && updateItemMutation();

  useEffect(() => {
    if (selectedPerson) {
      setSelected(selectedPerson);
    } else {
      if (userData && userData?.length > 0) {
        setSelected(userData[0]);
      }
    }
  }, [selectedPerson, userData]);

  const renderButtonContent = () => (
    <div className="person-item-container">
      <img src={selected?.profilePictureUrl} className={'profile-picture'} />
      <Typography className={'menu-chip-text'}>{selected?.name}</Typography>
    </div>
  );

  const renderItem = (item: any) => (
    <>
      <img src={item.profilePictureUrl} className={'profile-picture'} />
      <Typography className={'menu-item-text'}>{item.name}</Typography>
    </>
  );

  const onSelectItem = (user: AvailableUser) => {
    if (readonly) return;
    setSelected(user);
    if (onSelect) {
      onSelect(user);
    } else {
      if (!property || !itemId) return;
      updateItemMutationPayload[0]?.({
        [property]: itemId,
        body: {
          assigneeUserId: user.id,
        },
      });
    }
  };

  return (
    <DropdownPicker
      displayFullWidthPopover={displayFullWidthPopover}
      fetchItemsMutation={useFetchAvailableUsersToAssignMutation}
      fetchItemsFunctionParams={fetchAssignableUsersConfig}
      selectedListItem={selected}
      readonly={readonly}
      searchMode={true}
      getSearchProperty={(item) => item?.name}
      renderButtonContent={renderButtonContent}
      renderItem={renderItem}
      onListItemClick={onSelectItem}
      inputId={inputId}
    />
  );
};
