import { AutocompleteOption } from 'FindingDetails/store/api';
import { OperationalWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';

export enum TimelineFilter {
  '7_DAYS' = 'Last 7 Days',
  '14_DAYS' = 'Last 14 Days',
  '30_DAYS' = 'Last 30 Days',
  '60_DAYS' = 'Last 60 Days',
}

export const operationalDashboardFilterOptions: Array<AutocompleteOption> = [
  {
    value: TimelineFilter['7_DAYS'],
    label: TimelineFilter['7_DAYS'],
  },
  {
    value: TimelineFilter['14_DAYS'],
    label: TimelineFilter['14_DAYS'],
  },
  {
    value: TimelineFilter['30_DAYS'],
    label: TimelineFilter['30_DAYS'],
  },
  {
    value: TimelineFilter['60_DAYS'],
    label: TimelineFilter['60_DAYS'],
  },
];

export const operationalDashboardTrendMetricsTypeOptions: Array<AutocompleteOption> =
  [
    {
      value: OperationalWidgetAnalyticsType.TOTAL_NEW_FINDINGS,
      label: 'New Findings',
    },
    {
      value: OperationalWidgetAnalyticsType.ROOT_CAUSE,
      label: 'New Root Causes',
    },
    {
      value: OperationalWidgetAnalyticsType.AGGREGATED_RISK,
      label: 'New Aggregated Risks',
    },
    {
      value: OperationalWidgetAnalyticsType.RESOLVED_WITHIN_SLA,
      label: 'Resolved Within SLA',
    },
    {
      value: OperationalWidgetAnalyticsType.NEW_WITHIN_SLA,
      label: 'New Within SLA',
    },
    {
      value: OperationalWidgetAnalyticsType.EXCEPTIONS_REQUESTS,
      label: 'New Exception Requests',
    },
    {
      value: OperationalWidgetAnalyticsType.RESOLVED_FINDINGS,
      label: 'Resolved Findings',
    },
    {
      value: OperationalWidgetAnalyticsType.SLA,
      label: 'SLA Attainment Rate',
    },
    {
      value: OperationalWidgetAnalyticsType.AVERAGE_MTTR,
      label: 'Avg. MTTR',
    },
    {
      value: OperationalWidgetAnalyticsType.RESOLVED_OUTSIDE_SLA,
      label: 'Resolved Outside SLA',
    },
    {
      value: OperationalWidgetAnalyticsType.NEW_OUTSIDE_SLA,
      label: 'New Outside SLA',
    },
    {
      value: OperationalWidgetAnalyticsType.EXCEPTIONS_APPROVALS,
      label: 'New Exception Approved',
    },
  ];
