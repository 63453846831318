import Autocomplete from 'Common/components/Autocomplete';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import {
  getFilterState,
  getFindingTypes,
  getInitialFilterLoad,
  selectdashboardFilter,
  setdashboardFilters,
  setSelectedMetrics,
} from 'Dashboard/store';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import SimpleLineChart from 'shared/components/SimpleLineChart';
import {
  operationalDashboardFilterOptions,
  TimelineFilter,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OperationalWidgetAnalyticsType,
  OperationalWidgetData,
  OperationalWidgetDataTrend,
  OperationalWidgetDataTrendImpact,
} from 'shared/models/data/operational-widget-data.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OperationalWidgetCard from '../OperationalWidgetCard';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import NoDataToDisplayCard from '../NoDataCard';
import { ChartOptions } from 'chart.js';
import { Grid } from '@mui/material';
import DashboardChartCard from '../DashboardChartCard';
import { useGetDashboardAnalyticTrendsMutation } from 'Dashboard/store/api';
import { useTranslation } from 'react-i18next';
import { ChartDataItem } from 'shared/models/data/chart.model';
import { DashboardSection } from '../DashboardSection';

const mockSlaData: OperationalWidgetData = {
  trend: OperationalWidgetDataTrend.INCREASING,
  trendPercentage: 2,
  trendImpact: OperationalWidgetDataTrendImpact.POSITIVE,
  value: '57.80%',
};

interface OperationalDashboardSectionProps extends BaseComponentProps {}

export const OperationalDashboardSection: FunctionComponent<
  OperationalDashboardSectionProps
> = () => {
  const dispatch = useCommonDispatch();
  const { t: translation } = useTranslation();

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const filterState = useCommonSelector(getFilterState);

  const findingTypes = useMemo(() => {
    return getFindingTypes(filterState);
  }, [filterState['findingType']]);

  const getOperationalWidgetTitle = (type: OperationalWidgetAnalyticsType) => {
    return translation(`dashboards.widgets.${type}`);
  };

  const operationalWidgets = [
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.TOTAL_NEW_FINDINGS
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.BUG_ICON} />,
      type: OperationalWidgetAnalyticsType.TOTAL_NEW_FINDINGS,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.ROOT_CAUSE
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.TELESCOPE_ICON} />,
      type: OperationalWidgetAnalyticsType.ROOT_CAUSE,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.AGGREGATED_RISK
      ),
      subTitle: '',
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.HEXAGON_EXCLAMATION_ICON} />,
      type: OperationalWidgetAnalyticsType.AGGREGATED_RISK,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.RESOLVED_WITHIN_SLA
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />,
      type: OperationalWidgetAnalyticsType.RESOLVED_WITHIN_SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.NEW_WITHIN_SLA
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.BALLOT_CHECK_ICON} />,
      type: OperationalWidgetAnalyticsType.NEW_WITHIN_SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.EXCEPTIONS_REQUESTS
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.FILE_EXCLAMATION_ICON} />,
      type: OperationalWidgetAnalyticsType.EXCEPTIONS_REQUESTS,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.RESOLVED_FINDINGS
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_TO_SLOT_ICON} />,
      type: OperationalWidgetAnalyticsType.RESOLVED_FINDINGS,
    },
    {
      title: getOperationalWidgetTitle(OperationalWidgetAnalyticsType.SLA),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />,
      type: OperationalWidgetAnalyticsType.SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.AVERAGE_MTTR
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.TOOLS_ICON} />,
      type: OperationalWidgetAnalyticsType.AVERAGE_MTTR,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.RESOLVED_OUTSIDE_SLA
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_CHECK_ICON} />,
      type: OperationalWidgetAnalyticsType.RESOLVED_OUTSIDE_SLA,
    },

    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.NEW_OUTSIDE_SLA
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />,
      type: OperationalWidgetAnalyticsType.NEW_OUTSIDE_SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalWidgetAnalyticsType.EXCEPTIONS_APPROVALS
      ),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_TO_SLOT_ICON} />,
      type: OperationalWidgetAnalyticsType.EXCEPTIONS_APPROVALS,
    },
  ];

  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const selectTimeline = (timeline: TimelineFilter) => {
    dispatch(
      setdashboardFilters({
        ...dashboardFilter,
        timeline,
      })
    );
  };

  const [
    getAnalitycTrendsWidgetData,
    {
      data: analyticTrendsWidgetData,
      isLoading: analyticTrendsDataWidgetLoading,
    },
  ] = useGetDashboardAnalyticTrendsMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad)
      getAnalitycTrendsWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: findingTypes,
        timeline: dashboardFilter.timeline,
        types: dashboardFilter.metricsTypes,
      });
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter.businessUnitId,
    dashboardFilter.timeline,
    dashboardFilter.metricsTypes,
    findingTypes,
  ]);
  const renderChartWidget = (widgetData: any, isLoading: boolean) => {
    const hasNoData =
      (!widgetData || widgetData?.datasets.length === 0) && !isLoading;

    if (hasNoData) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }

    const widgetIndexMap = new Map(
      operationalWidgets.map((widget, index) => [widget.title, index])
    );

    widgetData?.datasets.sort((a: any, b: any) => {
      const indexA = widgetIndexMap.get(a.label) ?? Infinity;
      const indexB = widgetIndexMap.get(b.label) ?? Infinity;

      return indexA - indexB;
    });

    const chartOptions = presentationalConfigurationForWidgets[
      'analytic_trends'
    ].options as ChartOptions<'line'>;

    return (
      <>
        <SimpleLineChart
          fetchWidgetData={() => {}}
          isWidgetDataLoading={isLoading}
          widgetData={widgetData}
          style={presentationalConfigurationForWidgets['analytic_trends'].style}
          options={chartOptions}
          customLegendContainerId="operational-dashboard-section-metrics-chart-legend-container"
          displayLegend
          enableTooltip
        />
        {!isLoading && (
          <div id="operational-dashboard-section-metrics-chart-legend-container"></div>
        )}
      </>
    );
  };

  const onMetricClick = (type: OperationalWidgetAnalyticsType) => {
    const selectedMetrics = dashboardFilter.metricsTypes.slice();

    if (selectedMetrics.includes(type)) {
      const index = selectedMetrics.indexOf(type);
      selectedMetrics.splice(index, 1);
    } else {
      selectedMetrics.push(type);
    }

    dispatch(setSelectedMetrics(selectedMetrics));
  };

  const addAnalyticsTrendDataLabels = () => {
    if (analyticTrendsWidgetData) {
      return {
        ...analyticTrendsWidgetData,
        datasets: analyticTrendsWidgetData.datasets.map(
          (dataset: ChartDataItem) => ({
            ...dataset,
            label: getOperationalWidgetTitle(
              dataset.label as OperationalWidgetAnalyticsType
            ),
          })
        ),
      };
    }

    return analyticTrendsWidgetData;
  };

  return (
    <DashboardSection
      title={translation(`dashboards.widgets.metrics`)}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_PIE_ICON} />}
      filter={
        <Autocomplete
          model=""
          onChangeCallBack={(model, option) => {
            selectTimeline(
              (option as AutocompleteOption).value as TimelineFilter
            );
          }}
          classes={{
            root: 'operational-dashboard-section-timeline-filter-root',
            inputRoot:
              'operational-dashboard-section-timeline-filter-input-root',
            input: 'operational-dashboard-section-timeline-filter-input',
            listbox: 'operational-dashboard-section-timeline-filter-listbox',
          }}
          optionList={operationalDashboardFilterOptions}
          single
          placeholder="Select Timeline"
          initialSelectedValues={{
            value: dashboardFilter.timeline,
            label: dashboardFilter.timeline,
          }}
        />
      }
    >
      <div className="operational-dashboard-section-body">
        <Grid
          container
          spacing={1.5}
          className="operational-dashboard-section-body-card-list"
        >
          {operationalWidgets.map((widget, index) => (
            <Grid key={index} item xs={2} sm={2} md={2} lg={2} xl={2}>
              <OperationalWidgetCard
                {...widget}
                onItemClick={() => onMetricClick(widget.type)}
                isSelected={dashboardFilter.metricsTypes.includes(widget.type)}
              />
            </Grid>
          ))}
        </Grid>
        <div className="operational-dashboard-section-body-chart-list">
          <DashboardChartCard
            title="Trends"
            className="operational-dashboard-section-by-severity-chart-container-analytic-trends"
          >
            {renderChartWidget(
              addAnalyticsTrendDataLabels(),
              analyticTrendsDataWidgetLoading
            )}
          </DashboardChartCard>
        </div>
      </div>
    </DashboardSection>
  );
};
