import { ColDef, ColGroupDef } from 'ag-grid-community';
import ItemWithIcon from 'Common/components/ItemWithIcon';
import Status from 'Common/components/Status';
import moment from 'moment';
import RiskChip from 'Risk/components/RiskChip';
import CommonChip from 'shared/components/CommonChip';
import CommonIconCell from 'shared/components/CommonIconCell';
import { ResourceCell } from 'shared/components/ResourceCell/ResourceCell';

const gridDateFormat = 'MMM DD, YYYY [at] HH:mmA';

export const relatedFindingsTableColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogo}
        />
      );
    },
    initialFlex: 1.2,
    resizable: true,
  },

  {
    field: 'findingSource',
    headerName: 'Event Source',
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
        />
      );
    },
    initialFlex: 1,
    resizable: true,
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    sortable: true,
    cellRenderer: (params: any) => {
      return <RiskChip riskScore={params?.data?.riskScore} />;
    },
    initialFlex: 0.7,
    resizable: true,
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    sortable: true,
    cellRenderer: (params: any) => {
      return params?.data?.businessUnitName ? (
        <ItemWithIcon
          missingTitleKey={'N/A'}
          maxWidth={160}
          title={params?.data?.businessUnitName}
          displayIconOutline={false}
        ></ItemWithIcon>
      ) : (
        <div>-</div>
      );
    },
    initialFlex: 1,
    resizable: true,
  },

  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
    initialFlex: 1,
    resizable: true,
  },
  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <div className="cell-text-renderer">
          {moment(params.data?.externalCreatedAt).format(gridDateFormat)}
        </div>
      );
    },
    initialFlex: 1,
    resizable: true,
  },
];
