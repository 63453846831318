import FlowMain from 'Flow/components/Main';
import { ApplicationRoute } from 'shared/enums/route.enum';
import { RouteModel } from 'shared/models/data/route.model';
import { routePermissions } from './route-permission-list.data';
import IntegrationsMain from 'Integrations';
import SettingsMain from 'Settings/components/Main';
import DiagramLayout from 'WorkflowBuilder/components/Layout';
import { LAYOUT_MODE } from 'WorkflowBuilder/interfaces/layout';
import { RiskPage } from 'Risk/RiskPage';
import { CampaignsPage } from 'Campaigns/CampaignsPage';
import { CampaignDetailsPage } from 'CampaignDetails/CampaignDetailsPage';
import { DashboardPage } from 'Dashboard/DashboardPage';
import { AutomationManagement } from 'AutomationManagement/AutomationManagement';
import DashboardReports from 'Analytics/DashboardReports';
import ComparativeMain from 'Dashboard/Comparative/ComparativeMain';
import Redirect from 'shared/components/Redirect';
import AssetsPage from 'Assets';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import CampaignsManagmentPage from 'Campaigns/components/CampaignsManagmentPage';
import { CampaignTrackerPage } from 'Campaigns/components/CampaignTrackerPage';

export const routeList: Array<RouteModel> = [
  {
    name: ApplicationRoute.WORKFLOWS,
    path: 'flows',
    component: <FlowMain />,
    permissions: routePermissions[ApplicationRoute.WORKFLOWS],
  },
  {
    name: ApplicationRoute.INTEGRATIONS,
    path: 'integrations',
    component: <IntegrationsMain />,
    permissions: routePermissions[ApplicationRoute.INTEGRATIONS],
  },
  {
    name: ApplicationRoute.SETTINGS,
    path: 'settings',
    component: <SettingsMain />,
    permissions: routePermissions[ApplicationRoute.SETTINGS],
  },
  {
    name: ApplicationRoute.WORKFLOW_CREATE,
    path: 'builder',
    component: <DiagramLayout mode={LAYOUT_MODE.EDIT} />,
    permissions: routePermissions[ApplicationRoute.WORKFLOW_CREATE],
  },
  {
    name: ApplicationRoute.WORKFLOW_EDIT,
    path: 'builder/:workflowFamilyId/edit',
    component: <DiagramLayout mode={LAYOUT_MODE.EDIT} />,
    permissions: routePermissions[ApplicationRoute.WORKFLOW_EDIT],
  },
  {
    name: ApplicationRoute.WORKFLOW_SINGLE,
    path: 'builder/:workflowFamilyId',
    component: <DiagramLayout mode={LAYOUT_MODE.VIEW} />,
    permissions: routePermissions[ApplicationRoute.WORKFLOW_SINGLE],
  },
  {
    name: ApplicationRoute.RISKS,
    path: 'risks',
    component: <RiskPage />,
    permissions: routePermissions[ApplicationRoute.RISKS],
  },
  {
    name: ApplicationRoute.ASSETS,
    path: 'assets',
    component: <AssetsPage />,
    permissions: routePermissions[ApplicationRoute.ASSETS],
    featureFlag: SystemFeatureFlag.ASSETS_VIEW,
  },
  {
    name: ApplicationRoute.CAMPAIGNS,
    path: 'campaigns',
    component: <CampaignsManagmentPage />,
    permissions: routePermissions[ApplicationRoute.CAMPAIGNS],
  },
  {
    name: ApplicationRoute.CAMPAIGN_SINGLE,
    path: 'campaigns/:id',
    component: <CampaignTrackerPage />,
    permissions: routePermissions[ApplicationRoute.CAMPAIGNS],
  },
  {
    name: ApplicationRoute.FINDING_SINGLE,
    path: 'findings/:id/*',
    component: (
      <Redirect
        getUrl={(params) =>
          `/risks?activeTabId=none&openFindingId=${params.id}`
        }
      />
    ),
    permissions: routePermissions[ApplicationRoute.FINDING_SINGLE],
  },
  {
    name: ApplicationRoute.EVENT_SINGLE,
    path: 'iac/:id/*',
    component: (
      <Redirect
        getUrl={(params) =>
          `/risks?activeTabId=rootCause&openCodeEventId=${params.id}`
        }
      />
    ),
    permissions: routePermissions[ApplicationRoute.EVENT_SINGLE],
  },
  {
    name: ApplicationRoute.DASHBOARDS,
    path: 'dashboard/risk',
    component: <DashboardPage />,
    permissions: routePermissions[ApplicationRoute.DASHBOARDS],
  },
  {
    name: ApplicationRoute.AUTOMATION_MANAGEMENT,
    path: 'automation-management',
    component: <AutomationManagement />,
    permissions: routePermissions[ApplicationRoute.AUTOMATION_MANAGEMENT],
  },
  {
    name: ApplicationRoute.COMPARATIVE,
    path: 'dashboard/comparative',
    component: <ComparativeMain />,
    permissions: routePermissions[ApplicationRoute.DASHBOARDS],
  },
];

export const machineRoutePrefix = 'internal';

export const machineAccessibleRouteList: Array<RouteModel> = [
  {
    component: <DashboardReports />,
    path: `${machineRoutePrefix}/dashboards/reports`,
    name: 'Dashboard Reports',
  },
];
