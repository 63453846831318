import { Skeleton, Typography } from '@mui/material';
import Status from 'Common/components/Status';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { useGetFindingOperationalStatusQuery } from 'FindingDetails/store/api';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FindingItem from 'shared/components/FindingItem';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import {
  findingDateFormat,
  findingDateTimeFormat,
} from 'shared/fixtures/data/findings.data';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { TimeLineActorTypes } from '../../../Timeline/interfaces/finding-event';
import TimelineEventAvatar from '../../../Timeline/TimelineEventAvatar';
import { BaseFindingComponentProps } from 'shared/models/props/base-finding-component-props.model';

interface FindingContentStatusProps extends BaseFindingComponentProps {}

export const FindingContentStatus: FunctionComponent<
  FindingContentStatusProps
> = ({ findingId }) => {
  const { t: translation } = useTranslation();
  const [searchParams] = useQueryParams();

  const {
    data: findingContentStatusData,
    isLoading: findingContentStatusDataLoading,
    error: findingContentStatusDataError,
  } = useGetFindingOperationalStatusQuery(
    findingId || searchParams.openFindingId
  );

  const renderRelationSubSection = () => {
    if (findingContentStatusData?.relation) {
      return (
        <div className="finding-content-status-related-item-row finding-content-section-row">
          <FindingItem
            title={translation(`findings.subSections.ticket`)}
            id={'item-relation-id'}
          >
            <InteractiveLabel
              icon={
                <div className="finding-content-application-icon">
                  <img
                    src={findingContentStatusData?.relation?.metadata?.appLogo}
                  />
                </div>
              }
              label={findingContentStatusData?.relation?.metadata?.itemId}
              link={findingContentStatusData?.relation?.metadata?.link}
            />
          </FindingItem>

          <FindingItem
            title={translation(`findings.subSections.status`)}
            id={'item-relation-status'}
          >
            <Status
              readonly
              label={findingContentStatusData?.relation?.status}
              showIcon={false}
            />
          </FindingItem>

          <FindingItem
            title={translation(`findings.subSections.assignee`)}
            id={'item-relation-assignee'}
          >
            <p>
              {findingContentStatusData?.relation?.syncMetadata?.assigneeEmail}
            </p>
          </FindingItem>

          <FindingItem
            title={translation(`findings.subSections.lastUpdatedTime`)}
            id={'item-relation-last-updated-time'}
          >
            <p>
              {moment(findingContentStatusData?.relation?.updatedAt).format(
                findingDateTimeFormat
              )}
            </p>
          </FindingItem>
        </div>
      );
    }

    return <></>;
  };

  const renderTimelineSubSection = () => {
    if (findingContentStatusData?.timeline) {
      return (
        <div className="finding-content-status-activity-row">
          <FindingItem title={translation(`findings.subSections.lastActivity`)}>
            <div className="finding-content-status-last-activity">
              <div className="finding-content-status-last-activity-author">
                <TimelineEventAvatar
                  event={findingContentStatusData?.timeline}
                />
                <Typography
                  component="span"
                  className="finding-content-status-last-activity-author-text"
                >
                  {findingContentStatusData?.timeline.actorType ===
                  TimeLineActorTypes.USER
                    ? `${findingContentStatusData?.timeline.actorName} left a comment "${findingContentStatusData?.timeline?.message}"`
                    : findingContentStatusData?.timeline?.message}
                </Typography>
              </div>
              <div className="finding-content-status-last-activity-date">
                <InteractiveLabel
                  icon={
                    <OpusSvgIcon
                      type={SVG_ICON_TYPES.SIMPLE_CALENDAR_ICON}
                    ></OpusSvgIcon>
                  }
                  label={moment(
                    findingContentStatusData?.timeline?.createdAt
                  ).format(findingDateFormat)}
                />
              </div>
            </div>
          </FindingItem>
        </div>
      );
    }

    return <></>;
  };

  const renderSectionBody = () => {
    if (findingContentStatusDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={80} width={'100%'} />
        </div>
      );
    }

    if (
      !findingContentStatusDataLoading &&
      (findingContentStatusData === undefined ||
        findingContentStatusDataError ||
        (!findingContentStatusData?.relation &&
          !findingContentStatusData?.timeline))
    ) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-status-section-body">
        {renderRelationSubSection()}
        {renderTimelineSubSection()}
      </div>
    );
  };

  const renderSection = () => {
    if (
      !findingContentStatusDataLoading &&
      !findingContentStatusData?.timeline &&
      !findingContentStatusData?.relation
    )
      return <></>;

    return (
      <div className="finding-content-item finding-content-status-container">
        <ContentSection
          iconClassName="finding-content-section-icon"
          rootClassName="finding-content-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
          title={translation(`findings.sections.operationalStatus`)}
        >
          {renderSectionBody()}
        </ContentSection>
      </div>
    );
  };

  return renderSection();
};
