import {
  DashboardFilterOption,
  DashboardFilterType,
} from 'Dashboard/interfaces/Dashboard';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { OperationalWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';

export default class DashboardFilterHandler {
  static transformDashboardFilterToSearchParams(
    dashboardFilter: DashboardFilterType
  ): URLSearchParams {
    const searchParams: URLSearchParams = new URLSearchParams();

    for (const currentDashboardKey of Object.keys(dashboardFilter)) {
      const typedCurrentDashboardKey =
        currentDashboardKey as keyof DashboardFilterType;
      if (dashboardFilter[typedCurrentDashboardKey]?.length) {
        searchParams.set(
          typedCurrentDashboardKey,
          JSON.stringify(dashboardFilter[typedCurrentDashboardKey])
        );
      }
    }

    return searchParams;
  }

  static transformSearchParamsToDashboardFilter(
    searchParams: URLSearchParams
  ): DashboardFilterType {
    return Array.from(searchParams.keys()).reduce(
      (accumulator: any, searchParamKey: string) => {
        if (searchParams.get(searchParamKey)?.length) {
          accumulator[searchParamKey] = JSON.parse(
            searchParams.get(searchParamKey) as string
          );
        }

        return accumulator;
      },
      {}
    );
  }

  static transformFilterOptionsToListOfStrings(
    filterOptions: DashboardFilterType
  ): DashboardFilterType {
    return (
      Object.keys(filterOptions) as Array<keyof DashboardFilterType>
    ).reduce(
      (
        accumulator: DashboardFilterType,
        currentValue: keyof DashboardFilterType
      ) => {
        if (currentValue === 'timeline') {
          accumulator[currentValue] = filterOptions[currentValue];
          return accumulator;
        }

        if (filterOptions[currentValue]?.length) {
          accumulator[currentValue] = (
            filterOptions[currentValue] as Array<DashboardFilterOption | string>
          ).map((AutocompleteOption: DashboardFilterOption | string) => {
            if (typeof AutocompleteOption === 'string') {
              return AutocompleteOption;
            }

            return AutocompleteOption?.value;
          }) as any;
        }

        return accumulator;
      },
      {
        timeline: TimelineFilter['60_DAYS'],
        selectedScope: ['All'],
        selectedGroup: ['All'],
        selectedServices: ['All'],
        findingTypes: [],
        metricsTypes: [OperationalWidgetAnalyticsType.TOTAL_NEW_FINDINGS],
      }
    );
  }

  static getFilterValues(filterOptions: Array<FilterOption>) {
    const selectedMenuItems = filterOptions?.filter((item) => item.checked);
    let selectedValues = selectedMenuItems.map((item) => item.id);
    if (selectedValues.length === 1 && selectedValues[0] === 'All')
      selectedValues = [];

    return selectedValues;
  }
}
