import { FunctionComponent } from 'react';
import moment from 'moment';
import { findKey } from 'lodash';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import ContentSection from 'shared/components/ContentSection';
import FindingItem from 'shared/components/FindingItem';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import Status from 'Common/components/Status';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { findingDateTimeFormat } from 'shared/fixtures/data/findings.data';
import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import OpusSvgAppIcon from 'shared/components/IconComponents/OpusSvgAppIcon';
import { CodeEventItem } from 'Cloud2Code/interfaces/code-event-item.model';
import { RelationType } from 'Cloud2Code/interfaces/cloud-to-code-relation-type.enum';

interface Cloud2CodeOperationalStatusProps extends BaseComponentProps {
  codeEventRelatedItems?: CodeEventItem[];
  isLoading: boolean;
}

export const Cloud2CodeOperationalStatus: FunctionComponent<
  Cloud2CodeOperationalStatusProps
> = ({ codeEventRelatedItems, isLoading }) => {
  const { t: translation } = useTranslation();
  const [searchParams] = useQueryParams();
  const id = searchParams.openCodeEventId;

  const getAppLogo = (id: string) => {
    const iconKey = findKey(ApplicationTypes, (type: string) => type === id);
    return (
      <OpusSvgAppIcon
        height={18}
        width={18}
        type={ApplicationTypes[iconKey as keyof typeof ApplicationTypes]}
      ></OpusSvgAppIcon>
    );
  };

  const getSubSectionTitle = (type: RelationType) => {
    switch (type) {
      case RelationType.TICKET:
        return translation(`assets.details.ticket`);
      case RelationType.PULL_REQUEST:
        return translation(`assets.details.pullRequest`);
      default:
        return '';
    }
  };
  const renderRelationSubSection = (item: CodeEventItem, index: number) => {
    if (item) {
      return (
        <div
          className="finding-content-status-related-item-row finding-content-section-row"
          key={index}
        >
          <FindingItem title={getSubSectionTitle(item.relationType)}>
            <InteractiveLabel
              icon={
                item.appLogoUrl ? (
                  <div className="finding-content-application-icon">
                    <img src={item?.appLogoUrl}></img>
                  </div>
                ) : (
                  getAppLogo(item.metadata.appId)
                )
              }
              label={item.metadata?.itemId}
              link={item.metadata?.link}
            />
          </FindingItem>

          <FindingItem title={translation(`assets.details.statusColumn`)}>
            <Status readonly label={item?.status} showIcon={false} />
          </FindingItem>
          <FindingItem title={translation(`assets.details.assignee`)}>
            <p>{item?.assigneeEmail}</p>
          </FindingItem>

          <FindingItem title={translation(`assets.details.lastUpdatedTime`)}>
            <p>{moment(item?.updatedAt).format(findingDateTimeFormat)}</p>
          </FindingItem>
        </div>
      );
    }

    return <></>;
  };

  const renderSectionBody = () => {
    if (isLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={80} width={'100%'} />
        </div>
      );
    }

    if (!isLoading && codeEventRelatedItems?.length === 0) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-status-section-body">
        {codeEventRelatedItems?.map((item: CodeEventItem, index: number) =>
          renderRelationSubSection(item, index)
        )}
      </div>
    );
  };

  const renderSection = () => {
    if (
      !isLoading &&
      (codeEventRelatedItems === undefined ||
        codeEventRelatedItems?.length === 0)
    )
      return <></>;

    return (
      <div className="finding-content-item finding-content-status-container">
        <ContentSection
          iconClassName="finding-content-section-icon"
          rootClassName="finding-content-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
          title={translation(`assets.details.operationalStatus`)}
        >
          {renderSectionBody()}
        </ContentSection>
      </div>
    );
  };

  return renderSection();
};
