export class DateService {
  addWeeks(date: Date, weeks: number): Date {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }

  addMonths(date: Date, months: number): Date {
    return new Date(date.setMonth(date.getMonth() + months));
  }

  diffInDays(startDate: Date, endDate: Date): number {
    const startUTC = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const endUTC = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    return Math.floor((endUTC - startUTC) / millisecondsInADay);
  }

  diffInMonths(startDate: Date, endDate: Date): number {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const yearDifference = end.getFullYear() - start.getFullYear();
    const monthDifference = end.getMonth() - start.getMonth();

    // Calculate the total number of months between the two dates
    const totalMonthDifference = yearDifference * 12 + monthDifference;

    return totalMonthDifference;
  }
}
