const en = {
  translations: {
    common: {
      filters: 'filters',
      apply: 'apply',
      clear: 'clear',
      noOptions: 'no options',
      search: 'search',
      searchPlaceholder: 'Search',
      clickHere: 'Click Here',
      of: 'of',
      or: 'or',
      no: 'no',
      noItems: 'no items',
      comment: 'comment',
      step: 'step',
      import: 'import',
      export: 'export',
      areYouSure: 'Are you sure?',
      confirm: 'confirm',
      cancel: 'cancel',
      delete: 'delete',
      deleting: 'deleting',
      bump: 'bump',
      bumping: 'bumping',
      close: 'close',
      edit: 'edit',
      create: 'create',
      creating: 'creating',
      update: 'update',
      updating: 'updating',
      save: 'save',
      next: 'next',
      saving: 'saving',
      action: 'action',
      name: 'name',
      missingValue: 'N/A',
      crossroad: 'crossroad',
      youWantToProceed: 'you want to proceed',
      findings: 'findings',
      resources: 'resource',
      reason: 'reason',
      addNew: 'add new',
      refresh: 'Refresh',
      from: 'From',
      copyToClipboard: 'copy to clipboard',
      copiedToClipboard: 'copied to clipboard',
      copyLink: 'copy link',
      copiedLink: 'copied link',
      anErrorOccured: 'an error occured',
      isRequiredField: 'is a required field',
      environments: 'Environments',
      parameters: 'parameters',
      yet: 'yet',
      connected: 'connected',
      rule: 'rule',
      rules: 'rules',
      defined: 'defined',
      for: 'for',
      and: 'and',
      connect: 'Connect',
      connecting: 'Connecting',
      noData: 'No data',
      sla: 'sla',
      noDataFound: 'No data found',
      noDataFoundDescription: 'Change from the last period',
      startTyping: 'Type your ...',
      learnMore: 'Learn More',
      optional: 'Optional',
      poweredBy: 'Powered by',
      loading: 'Loading',
      defaultValues: {
        noRiskCategory: 'No Category Assigned',
      },
      quickFilters: {
        assignedToMe: '{{param1}} assigned to me',
        ownedByMe: '{{param1}} I own',
      },
      status: {
        open: 'Open',
        inProgress: 'In Progress',
        inReview: 'In Review',
        suppress: 'Suppress',
        resolved: 'Resolved',
      },
      selectAll: 'Select All',
      item: 'Item',
      items: 'Items',
      selected: 'Selected',
      yes: 'Yes',
      stay: 'Stay',
      youHaveUnsavedChanges: 'You have unsaved changes',
      areYouSureYouWantToExit: 'Are you sure you want to exit?',
      showMore: 'Show more',
      showLess: 'Show less',
      noTagsAvailable: 'No tags available',
      id: 'ID',
      exportCsv: 'Export CSV',
      groupResourceCve: 'Group CVEs Duplications',
      csvDownloadInfo: 'CSV download may require some time to complete.',
      deployStack: 'Deploy Stack',
      archive: 'Archive',
      unarchive: 'Unarchive',
      lastSeen: 'Last Seen',
    },
    onboarding: {
      title: 'Welcome to Opus Security',
      description:
        'Opus orchestrates remediation for every security finding: the platform identifies the root cause and proper organizational context of the risk, delivers actionable remediation steps across code and cloud and drives effective collaboration throughout the organization.',
      integrationsTitle: 'Please Add Integrations',
      integrationsDescription:
        'Integrates with existing cloud and security tools to gain visibility into security findings across the entire organization and automate remediation flows.',
      beforeYouStart: 'Before you start',
      beforeYouStartDescription:
        'Opus orchestrates remediation for every security finding: the platform identifies the root cause and proper organizational context of the risk, delivers actionable remediation steps across code and cloud and drives effective collaboration throughout the organization.',
    },
    dashboards: {
      title: 'Dashboard',
      tabs: {
        code: 'Code',
        operational: 'Operational',
        posture: 'Posture',
      },
      filter: {
        inputs: {
          externalCreatedAt: 'Time Range',
          cloudProvider: 'Cloud Provider',
          team: 'Team',
          businessUnit: 'Service',
          environment: 'Environment',
          resourceType: 'Resource Type',
          findingSource: 'Finding Source',
        },
      },
      widgets: {
        extended: {
          slaFullfillment: 'SLA Fullfillment',
          expiring: 'Expiring',
          resolved: 'Resolved',
          mttr: 'MTTR',
          savedHours: 'Saved Hours',
          estSaving: 'Est. Saving',
          orchestrationCoverage: 'Orchestration Coverage',
        },
        metrics: 'Metrics',
        totalMetrics: 'Total Metrics',
        findingsByTicketAge: 'Findings by Ticket Age',
        operationalOverview: 'Operational Overview',
        openFindings: 'Open Findings',
        totalRisk: 'Aggregated Risks',
        rootCauses: 'Root Causes',
        slaAttainmentRate: 'SLA Attainment Rate',
        pendingExceptions: 'Pending Exceptions',
        activeExceptions: 'Active Exceptions',
        resolvedFindings: 'Resolved Findings',
        averageMttr: 'Avg. MTTR',
        byCategory: 'Findings By Category',
        findingsBySeverity: 'Findings by Severity',
        findingsRequiringAttention: 'Findings Requiring Attention',
        resourcesRequiringAttention: 'Resources Requiring Attention',
        issuesOverTime: 'Issues Over Time',
        findingSeverityRemediation: 'Findings Severity & Remediation',
        mttrOverTime: 'MTTR Over Time',
        overSpecificValue: 'Over {{value}}',
        fixesPerApplication: 'Fixes Per Service',
        fixesPerRepository: 'Fixes Per Repository',
        topRootCausesToAddress: 'Top Root Causes To Address',
        topIacFilesToFix: 'Top IAC Files To Fix',
        fixesOverTime: 'Fixes Over Time (Line Per Type Of Environment)',
        findingsPerApplication: 'Findings Per Service',
        topGuardrailsTriggered: 'Top GuardRails Triggered',
        findingsByCategory: 'Findings By Source',
        slaExceededPerApplication: 'SLA Exceeded Per Service',
        proactiveSecurity: 'Proactive Security',
        riskAssessment: 'Risk Assessment per Surface',
        repositories: 'Repositories',
        artifacts: 'Artifacts',
        cloudAccounts: 'Cloud Accounts',
        topRootCauses: {
          title: 'Top 5 Root Causes',
          riskInfo: 'Risk Info',
          impactScore: 'Impact Score',
          numberOfFindings: '# of Findings',
          viewFindings: 'View Findings',
        },
        topRiskOwners: {
          title: 'Top 5 Risk Owners',
          noOwner: 'No assigned owner',
          owner: 'Resource Owner',
          riskScore: 'Risk Score',
          numberOfFindings: '# of Findings',
          ack: 'Ack',
          slackMessage: 'Slack Message',
        },
        topCampaigns: {
          title: 'Top 5 Campaigns',
          potentialScore: 'Top Potential Scope',
          riskScore: 'Risk Score',
          numbOfRisks: '# of Risks',
          affectedAsset: 'Affected Assets',
          startCampaign: 'Start Campaign',
        },
        riskAssesmentSurface: {
          title: 'Risk Assessment per Surface',
          name: 'Name',
          source: 'Event Source',
          severityBreakdown: 'Severity Breakdown',
          trendChart: 'Trend',
          trendChange: 'Trend Change',
          score: 'Score',
        },
        businessUnitCarousel: {
          openTickets: 'Open Tickets',
        },
        total_new_finding: 'New Findings',
        root_cause: 'New Root Causes',
        aggregated_risk: 'New Aggregated Risks',
        resolved_findings: 'Resolved Findings',
        exceptions_requests: 'New Exception Requests',
        exceptions_approvals: 'New Exception Approved',
        new_within_sla: 'New Within SLA',
        new_outside_sla: 'New Outside SLA',
        resolved_within_sla: 'Resolved Within SLA',
        resolved_outside_sla: 'Resolved Outside SLA',
        average_mttr: 'Avg. MTTR',
        sla: 'SLA Attainment Rate',
      },
      generalInfo: {
        guardrailsTitle: 'GuardRails Active',
        numberOfRootCauses: '# Root Causes',
        codeVsCloud: 'Code vs Cloud',
      },
      cards: {
        openFindings: 'Open Findings',
      },
      riskAssessmentSurface: {
        infrastructure: 'Infrastructure',
        application: 'Application',
      },
    },
    findings: {
      title: 'Findings',
      sections: {
        operationalStatus: 'Operational Status',
        riskScoreBreakdown: 'Risk Score Breakdown',
        evidence: 'Evidence',
        owners: 'Owners',
        sourceInformation: 'Source Information',
        relatedFindings: 'Related Findings',
        affectedPackages: 'Affected Packages',
        additionalData: 'Additional Data',
        risks: 'Risks',
        mostAffectedVendors: 'Most Affected Vendors',
        threats: 'Threats',
        threatActors: 'Threat Actors',
        discussions: 'Discussions',
        discussionsLast10: 'Discussions (last 10)',
      },
      subSections: {
        environment: 'Environment',
        resource: 'Resource',
        cloudToCode: 'Cloud to Code',
        ticket: 'Ticket',
        status: 'Status',
        assignee: 'Assignee',
        lastUpdatedTime: 'Last Updated Time',
        lastActivity: 'Last Activity',
        service: 'Service',
        group: 'Group',
        workspace: 'Workspace',
        type: 'Type',
        resourceName: 'Resource Name',
        resourceId: 'Resource ID',
        tags: 'Tags',
        fileName: 'File Name',
        repository: 'Repository',
        associatedFindings: 'Associated Findings',
        suggestedFixAvailable: 'Suggested Fix Available',
        goToRootCause: 'Go To Root Cause',
        goToResource: 'Go To Resource',
        goToRisksView: 'Go To Risks View',
        sensorName: 'Sensor Name',
        severity: 'Severity',
        sensorFindingId: 'Sensor Finding ID',
        sdlc: 'SDLC',
        availableActions: 'Available Actions',
        confirmActions: 'Confirm Actions',
        environmental: 'Environmental',
        external: 'External',
        sensorSeverity: 'Sensor Severity',
        complianceRequirements: 'Compliance  Requirements ',
        externalFacing: 'External Facing',
        sensitiveData: 'Sensitive Data',
        base: 'Base',
        intelligence: 'Intelligence',
        rating: 'Rating',
        riskAttributes: 'Risk Attributes',
      },
      tabs: {
        summary: 'Summary',
        workflow: 'Workflow',
        titles: {
          summary: 'Finding',
        },
      },
      pullrequest: {
        createSuccess: 'Pull Request was Created Successfully',
        createFailed: 'Failed to Create a Pull Request',
        linkPrefix: 'PR Link',
      },
      ticket: {
        project: 'Project',
        assigneeEmail: 'Assignee Email',
        createMultiFindingsTicket: 'Create Multi Finding Ticket',
        createTicket: 'Create Ticket',
        unlinkTicket: 'Unlink Ticket',
        unlink: 'Unlink',
        creatingTicket: 'Creating ticket',
        unlinking: 'Unlinking',
        unlinkTickets: 'Unlink Tickets',
        createRootTicket: 'Create Root Cause Ticket',
        multiFindingsWarning:
          'You are about to create a ticket for the selected &nbsp;<b>{{param1}}</b>&nbsp; findings',
        unlinkTicketWarning:
          'Are you sure you want to unlink ticket from this finding?',
        unlinkTicketsWarnings:
          'Are you sure you want to unlink tickets from {{param1}} findings',
        automateTicket: 'Automate Ticket Assignment',
        createTicketSuccess: 'Successfully Created ',
        unlinkTicketSuccess: 'Successfully Unlinked ',
        unlinkTicketFailed: 'Failed to Unlink',
        createTicketFailed: 'Failed to Create',
        createTicketPartially: 'Partially Created',
        createSuccessMessage:
          'Opus created &nbsp;<b>{{param1}}</b>&nbsp; ticket for the following items',
        createSuccessMessageMultipleFindings:
          'Opus created ticket for the following &nbsp;<b>{{param1}}</b>&nbsp; items',
        unlinkSuccessMessage:
          'Opus unlinked &nbsp;<b>{{param1}}</b>&nbsp; ticket for the following items',
        createFailMessage:
          'Opus was unable to create &nbsp;<b>{{param1}}</b>&nbsp; ticket for the following items',
        createFailMessageMultipleFindings:
          'Opus was unable to create ticket for the following &nbsp;<b>{{param1}}</b>&nbsp; items',
        unlinkFailMessage:
          'Opus was unable to unlink &nbsp;<b>{{param1}}</b>&nbsp; ticket for the following items',
        viewTicket: 'View Ticket',
        noActiveConnections: 'Please configure a ticketing system first',
      },
      action: {
        changeAssignee: 'Change Assignee',
        changeStatus: 'Change Status',
        uanbleToChange: 'Unable to change {{param}}',
        status: 'Status',
        assignee: 'Assignee',
        bulkActionProgress: 'Bulk action in progress ...',
        bulkActionSuccess: 'Bulk action succeeded',
        bulkActionFailed: 'Bulk action failed',
        selectAssignee: 'Please select assignee',
        selectStatus: 'Please select status',
      },
      filter: {
        fields: {
          severity: 'Severity',
          category: 'Category',
          resourceType: 'Resource Type',
          updatedAt: 'Last Update',
          createdAt: 'Opened',
          status: 'Status',
          assigneeUserId: 'Assignee',
          cloudProvider: 'Cloud Provider',
          findingSource: 'Source',
          businessUnitId: 'Service',
          businessUnitEnvironmentType: 'Environment Type',
          businessUnitEnvironment: 'Environment',
        },
        systemFilters: 'System Filters',
        savedFilters: 'Saved Filters',
        deleteQuickFilterWarning:
          ' Do you want to delete "{{param1}}" from the Quick Filter?',
        newQuickFilter: 'New quick filter',
        newQuickFilterLabel: 'How do you want to call to the new Quick Filter',
        filterDelete: 'Filter is successfully deleted',
        filterCreated: 'Filter is successfully saved',
        filterCreatedFailed: 'Filter saving failed',
        noFilters: 'There is no filter saved yet',
      },
      table: {
        columns: {
          cloudProvider: 'Provider',
          source: 'Source',
          title: 'Finding',
          severity: 'Severity',
          resourceType: 'Resource Type',
          resourceName: 'Resource Name',
          createdAt: 'Opened',
          updatedAt: 'Last Update',
          status: 'Status',
          assigneeUserId: 'Assignee',
          product: 'Product',
          category: 'Category',
        },
      },
      details: {
        addResourceOwner: 'Add Resource Owner',
        addResourceOwnerError: 'Adding resource owner to this finding failed',
        email: 'Email',
        emailRequired: 'Email is required',
        reason: 'Reason',
        reasonRequired: 'Reason is required',
        timeElapsed: 'Time Elapsed',
        description: 'Description',
        actionDetails: 'Action Details',
        crossroadDetails: 'Crossroad Details',
        created: 'Created',
        lastUpdated: 'Last Updated',
        firstSeen: 'First Seen',
        sensor: 'Sensor',
        resources: 'Resources',
        cveList: 'Linked CVEs',
        noResourcesForFinding: 'No resources found for this finding',
        id: 'ID',
        fileName: 'File Name',
        iac: 'IaC',
        vulnerablePackage: 'Vulnerable Package',
        createdBy: 'Created By',
        lastModifier: 'Last Modifier',
        timeline: 'Timeline',
        noEvents: 'There are no events yet',
        postComment: 'Leave a comment...',
        actionsTaken: 'Actions taken',
        confirmActions: 'Confirm Actions',
        askingForActions: 'Step {{param1}} is asking you to pick actions',
        submittedActions: 'Successfully submitted',
        selectedActions: 'You have selected the following options',
        relatedActionItems: 'Related Items',
        noInfoForAction: 'No info to show',
        guardrailTriggered: 'GuardRail Triggered',
        availableActions: 'Available Actions',
        createdAt: 'Created At',
        category: 'Category',
        openWorkItem: 'Open Work Item',
        createJiraIssue: 'Create Jira Issue',
        createAzureDevopsWorkItem: 'Create Azure DevOps Work Item',
        addOwner: 'Add Owner',
        findingMissing: 'Finding Missing',
        findingMissingInfoMessage:
          "We couldn't find any results for your search. Our bad, let's try again",
        relatedFindingsInfo: 'This finding has related findings.',
        checkInfo: 'Check Info',
        status: {
          open: 'Open',
          inProgress: 'In Progress',
          resolved: 'Resolved',
          inReview: 'In Review',
          suppress: 'Suppress',
          pendingException: 'Pending Exception',
          pendingApproval: 'Pending Approval',
          exception: 'Exception',
        },
        systemActions: {
          rerun: {
            title: 'Rerun Workflows',
            shortTitle: 'ReRun',
            isSuccess: 'Workflows started',
          },
          remap: {
            title: 'Remap',
            shortTitle: 'ReMap',
            isSuccess: 'Remap started',
          },
          reAssociate: 'ReAssociate',
        },
        syncIaC: 'Map Repos',
        syncIaCStarted: 'Mapping repos started',
        widgets: {
          ownershipTitle: 'Resource Owner',
          environmentTitle: 'Environment Details',
          firstName: 'First Name',
          lastName: 'Last Name',
          mobilePhone: 'Mobile Phone',
          businessUnitOwner: 'Unit Owner',
          cloudEnvironmentAlias: 'Environment Alias',
          cloudProvider: 'Provider',
          cloudWorkspaceExternalId: 'Workspace ID',
        },
        aliases: 'Aliases',
        publishDate: 'Publish Date',
        tags: 'Tags',
        source: 'Source',
        type: 'Type',
      },
      itemRelations: {
        createDialog: {
          notifyTicketAssignee: 'Notify Ticket Assignee',
        },
      },
      errorMessages: {
        ticketBulkLimitMessage:
          'You cannot run bulk ticketing actions for more than {{bulkActionHardLimit}} items.',
        actionsBulkLimitMessage:
          'You cannot run bulk actions for more than {{bulkActionHardLimit}} items.',
      },
      intelligence: {
        riskIndex: 'Risk Index',
        dateUpdated: 'Date Updated',
        indicators: 'Indicators',
      },
    },
    exceptions: {
      modals: {
        createRequestExceptionTitle: 'Request Exception',
        declineRequestExceptionTitle: 'Decline Exception',
      },
      buttons: {
        sendRequest: 'Send Request',
        sendingRequest: 'Sending Request',
        decline: 'Decline',
        declining: 'Declining',
        approve: 'Approve',
        approving: 'Approving',
        revokeException: 'Revoke Exception',
        revokingException: 'Revoking Exception',
        cancelRequest: 'Cancel Request',
        cancelingException: 'Canceling Exception',
      },
      forms: {
        createExceptionRule: 'Create Exception Rule',
        thisSpecificAlert: 'This specific alert',
        theResource: 'The Resource',
        revokeExceptionIn: 'Revoke Exception in',
        explanationForYourRequest:
          'Please Provide an Explanation for your Request',
        declineThisRequest: 'Why do you decline this request?',
      },
      details: {
        exceptionStatus: 'Exception Status',
        expiration: 'Expiration',
        expirationTime: 'Expiration Time',
        expirationDate: 'Expiration Date',
        requestedBy: 'Requested By',
        approvedBy: 'Approved By',
        declinedBy: 'Declined By',
        createErrorMessage: 'Opus could not create an exception at this time.',
        actionErrorMessage: 'Opus could not perform this action at this time.',
      },
    },
    cveList: {
      title: 'Cve List',
      table: {
        columns: {
          id: 'CVE-ID',
          score: 'CVSS Score',
          packagesCount: 'Packages',
        },
        detail: {
          columns: {
            packageName: 'Package Name',
            installedVersion: 'Installed Version',
            fixedVersion: 'Fixed Version',
          },
        },
      },
    },
    campaigns: {
      title: 'Campaigns',
      campaignTracker: 'Campaign Tracker',
      burndown: 'Burndown',
      findings: {
        table: {
          riskScore: 'Risk Score',
          title: 'Finding',
          severity: 'Severity',
          resourceName: 'Resource Name',
          resourceOwner: 'Resource Owner',
          sla: 'SLA',
          ticket: 'Ticket',
          ticketStatus: 'Ticket Status',
          findingStatus: 'Finding Status',
          lastUpdate: 'Last Update',
        },
        openIssues: 'My Open Issues',
        updatedIssues: 'Updated Issues',
        awaitingAttention: 'Awaiting Attention',
        noRelatedTickets: 'No Related Tickets',
      },
      widgets: {
        findingsByStatus: 'Findings by Status',
      },
      metrics: {
        workBreakdown: 'Work Breakdown',
        ticketCompleted: 'Ticket Completed',
        completedPastWeek: 'Completed in the Past Week',
        newFindings: 'New Findings',
        remainingDays: 'Remaining Days',
      },
      details: {
        businessPriority: 'Service Priority',
        assignee: 'Assignee',
        archiveCampaign: 'Opus will archive this view in {{param1}} days',
        creatingCampaign: 'This campaign is being created',
        requiredBurndownSpeed: 'Required burndown speed',
        closedIssues: 'Closed Issues',
      },
      modal: {
        title: 'Create Campaign',
        labels: {
          name: 'Campaign Name',
          dateRange: 'Date Range',
          startTime: 'Start Date',
          endDate: 'End Date',
          assignees: 'Campaign Assignees',
          businessPriority: 'Service Priority',
        },
        helperText: {
          dateRange: 'What is the length of the campaign, e.g., time to track',
          assignees: 'Who are the relevant stakeholders?',
          businessPriority:
            'What is the current service priority of the campaign?',
        },
        validationErrors: {
          name: 'Campaign Name is required',
          startTime: 'Start Date is required',
          endDate: 'End Date is required',
          assignees:
            'At least one assignee should be selected for the campaign',
          businessPriority: 'Service Priority is required',
          dateRange:
            'At least one time range should be selected for the campaign',
          creatingCampaign: 'Creating campaign ...',
          creatingCampaignFailed: 'Creating campaign failed!',
        },
        placeholders: {
          name: 'E.g. Sprint 12 Campaign',
          dateRange: 'E.g. 1 Week',
          startTime: 'E.g. Wed 01 Feb 2023',
          endDate: 'E.g. Tue 28 Feb 2023',
          assignees: 'E.g. John Doe, Susan Wojcicki',
          businessPriority: 'E.g. Moderate',
        },
      },
    },
    risk: {
      groupBy: 'GROUP BY',
      startCampaign: 'Start Campaign',
      changeColumns: 'Add / Remove Columns',
      openTicket: 'Open Ticket',
      ticketActions: 'Ticket Actions',
      actions: 'Actions',
      runCampaign: 'Run Campaign',
      importFindings: 'Import Findings',
      findingGroups: {
        table: {
          risk: 'Risk',
          riskScore: 'Risk Score',
          securityStandard: 'Security Standard',
          codeVsCloud: 'Code vs. Cloud',
          rootCauses: 'Root Causes',
          category: 'Risk Category',
        },
      },
      import: {
        description:
          ' Choose your file application and format for importing findings into the Opus platform',
        integration: 'Integration',
        integrationPlaceholder: 'Select an integration',
        format: 'Format',
        fileImportedSuccessfully: 'File was imported successfully',
        fileImportInProgress: 'File is getting imported',
        fileImportFailed: 'File import failed',
        clickToUpload: 'Click to upload',
        dragAndDrop: 'or drag and drop',
        maxFileSize: 'Max. File Size',
        file: 'File',
        browseFile: 'Browse File',
      },
      findings: {
        table: {
          riskScore: 'Risk Score',
          severity: 'Severity',
          eventSource: 'Event Source',
          resource: 'Resource',
          businessUnit: 'Service',
          environment: 'Environment',
          source: 'Source',
          assignee: 'Assignee',
          updatedDate: 'Updated Date',
          sla: 'SLA',
          status: 'Status',
          actions: 'Actions',
        },
      },
      filters: {
        riskScore: 'Risk Score',
        category: 'Risk Category',
        severity: 'Severity',
        owner: 'Resource owner',
        eventSource: 'Event Source',
        resource: 'Resource',
        businessUnit: 'Service',
        environmentType: 'Environment Type',
        source: 'Source',
        assignee: 'Assignee',
        status: 'Status',
        updatedDate: 'Updated Date',
        slaStatus: 'SLA Status',
        riskCategory: 'Risk Category',
        aiFiltersTitle:
          'Type in your prompt below to search through your risks',
        aiFiltersHelperText: '*Powered by Chat GPT',
      },
    },
    flows: {
      table: {
        columns: {
          title: 'Flow Title',
          lastEdited: 'Last Edited',
          lastRunTime: 'Last Run Time',
          type: 'Type',
          actions: 'Actions',
        },
      },
      details: {
        myFlows: 'My Flows',
        failedImport: 'Failed to import workflow',
        runWorkflow: 'Run Workflow',
        importError: 'Import Error',
        selectFindingWarning: 'Select a finding in the Workflow Trigger panel',
        selectTriggersWarning:
          'Please select at least one trigger in order to save this workflow',
        missingWorkflowName: 'Workflow Name is required',
        importDone: "You're Done!",
        yourFlow: 'Your flow',
        startANewFlow: 'Start a new flow',
        uploadedSuccessfully: 'Your flow "{{param1}}" uploaded successfully',
        importFlow: 'Import Flow',
        addFlow: 'Add Flow',
        workflowTrigger: 'Workflow Trigger',
        setWorkflowTrigger: 'Set Workflow Trigger',
        addTool: 'Add Tool',
        addCrossroad: 'Add Crossroad',
        savedWorkflowSuccessfully: 'Workflow Saved Successfully',
        retrievingWorkflowConfiguration: 'Retrieving Workflow Configuration',
        savingWorkflowConfiguration: 'Saving Workflow Configuration',
        removeFlowWarning: 'Are you sure you want to delete "{{param1}}"?',
        wantToRemoveStep: 'you want to remove this step',
        updateTool: 'Update Tool',
        selectTool: 'Select Tool',
        chooseAction: 'Choose Action',
        selectAction: 'Select Action',
        deleteAction: 'Delete action',
        removeActionWarning: 'Are you sure you want to remove this step?',
        additionalSettings: 'Additional Settings',
        id: 'ID',
        editYourWorkflow: 'Edit your workflow',
        typeWorkflowName: 'Type workflow name',
        addNewPath: 'Add New Path',
        deleteCrossroad: 'Delete Crossroad',
        removeCrossroadWarning:
          'Removing the crossroad will remove all the routes and nodes connected to it. Are you sure you want to proceed?',
        removePath: 'Remove path',
        setCondition: 'Set Condition',
        selectEventType: 'Select Event Type',
        selectFinding: 'Select Finding',
        timeLineEventLabel: 'Timeline Event',
        workflowAffectOnFailLabel: 'Continue Workflow If Step Fail',
        timeLineEventPlaceholder: 'Add text and finding information...',
        noWorkflowsFound: 'No workflows found',
        loadingWorkflows: 'Loading Workflows...',
        pleaseSelectWorkflow: 'Please select a workflow',
      },
    },
    assets: {
      title: 'Assets',
      tabs: {
        iac: 'IaC',
        resources: 'Resources',
      },
      table: {
        columns: {
          findingType: 'Finding Type',
          sourceFile: 'File',
          repository: 'Repository',
          numberOfFindings: '# of Findings',
          impactScore: 'Impact Score',
          environment: 'Environment Criticality',
          resourceType: 'Resource Type',
          lastModifiedBy: 'Last modified by',
          lastSeen: 'Last Seen',
          lastModified: 'Last modified',
          commit: 'Commit',
          branch: 'Branch',
          codeFixAssignee: 'Code fix assignee',
        },
      },
      titles: {
        iacFindingFix: 'Code Details',
      },
      details: {
        impactScore: 'Impact Score',
        totalFindings: '# of Findings',
        maxScore: 'Max Score',
        codeDetails: 'Code Details',
        fileName: 'File Name',
        codeRepo: 'Code Repo',
        branch: 'Branch',
        lastModifier: 'Last Modifier',
        commit: 'Commit',
        modificationTime: 'Modification Time',
        codeSnippet: 'Code Snippet',
        codeFixSnippet: 'Code Fix Snippet',
        operationalStatus: 'Operational Status',
        ticket: 'Ticket',
        pullRequest: 'Pull Request',
        statusColumn: 'Status',
        assignee: 'Assignee',
        lastUpdatedTime: 'Last Updated Time',
        findingsByIac: 'Code Related Findings',
        iacFindingFix: 'Code Details',
        iacDetails: 'IaC Details',
        evidence: 'Evidence',
        iacFindings: 'IaC Findings',
        originalCode: 'Original',
        codeFix: 'Code Fix',
        reviewPr: 'Review PR',
        createPr: 'Create PR',
        creatingPr: 'Creating PR',
        inNumberOfDays: 'in {{days}} days',
        identifiedTags: 'Identified tags',
        identifiedTagsNotFound: 'No tags can be found for this context',
        status: {
          new: 'New',
          suppressed: 'Suppressed',
        },
        iacRelatedFindings: 'Related Findings',
        noRelatedFindings: 'No releated findings found for this IaC',
        noRelatedItems: 'No releated items found for this IaC',
      },
    },
    connections: {
      title: 'Connections',
      tabs: {
        myIntegrations: 'My Integrations',
        findingSources: 'Finding Sources',
      },
      details: {
        addNewIntegration: 'Add New Integration',
        addNewWebhook: 'Add New Webhook',
        addNewPollingSubscription: 'Add New Polling Subscription',
        connectYourName: 'Connect Your {{param1}}',
        connectionName: 'Connection Name',
        connectionNameIsRequiredField: 'Connection Name is a required field',
        defaultConnection: 'Default Connection',
        testConnection: 'Test Connection',
        configureWebhook: 'Configure Webhook',
        editPollingSubscription: 'Edit Polling Subscription',
        webhooks: 'Webhooks',
        webhookName: 'Webhook Name',
        webhookEndpoint: 'Webhook Endpoint',
        confirmationData: 'Confirmation Data',
        copyAndPasteWebhookConfiguration:
          'Copy and paste at your Webhook endpoint configuration',
        webhookSavedConfiguratiomMessage:
          'Once the webhook is saved at your confirmation page, confirmation data will show below, copy the relevant data back at the configuration window',
        noAvailableConnectMethods: 'No available connect methods',
        selectApplication: 'Select Application',
        noApplications: 'No applications',
        noConnectionYet: 'No {{param1}} yet',
      },
      errors: {
        completeErrorMessage:
          '{{applicationName}} integration failed, Opus was unable to complete the {{applicationName}} integration.',
        verifyErrorMessage:
          '{{applicationName}} integration failed, Opus was unable to verify the {{applicationName}} integration.',
      },
    },
    automationManagement: {
      title: 'Automation Management',
      filters: {
        onlyRuleDefined: 'Show active guardrails',
      },
      details: {
        withTag: 'With tag',
        remediation: 'Remediation',
        requireApproval: 'Require Approval',
        addRule: 'Add Rule',
        ruleDefined: '{{param1}} Rule defined',
        rulesDefined: '{{param1}} Rules defined',
        savingAutomationRuleConfiguration:
          'Saving automation rule configuration',
        automationRuleConfigurationSavedSuccessfully:
          'Automation rule configuration saved successfully',
      },
      labels: {
        businessUnits: 'Services',
        environments: 'Environments',
      },
    },
    settings: {
      title: 'Settings',
      tabs: {
        services: 'Services',
        workspaces: 'Workspace',
        sla: 'SLA',
        teams: 'Teams',
        automationRules: 'Automation Rules',
        reportRules: 'Reports',
        riskCustomization: 'Risk Customization',
        organization: 'Organization',
        users: 'Users',
      },
      table: {
        columns: {
          businessUnits: {
            serviceName: 'Name',
            serviceImpact: 'Service Impact',
            workspaces: 'Workspaces',
            ticketing: 'Ticketing',
            riskBadges: 'Risk Badges',
            criticality: 'Criticality',
            associatedRepository: 'Associated Repository',
            environments: 'Environments',
            businessUnitOwner: 'Service Owner',
            actions: 'Actions',
            stakeholders: 'Stakeholders',
          },
          cloudWorkspaces: {
            provider: 'Provider',
            workspaceEnvironmentType: 'Environment Type',
            workspaceName: 'Name',
            workspaceId: 'ID',
            workspaceType: 'Workspace Type',
            services: 'Services',
            actions: 'Actions',
            deleteWorkspace: 'Delete Workspace',
          },
          sla: {
            severity: 'Severity',
            slaHours: 'SLA Hours',
            actions: 'Actions',
            deleteSla: 'Delete Sla',
          },
          users: {
            user: 'User',
            roles: 'Roles',
            organization: 'Organization',
            deleteUser: 'Delete User',
            joined: 'Joined',
            lastSeen: 'Last Seen',
          },
          teams: {
            teamName: 'Team Name',
            teamOwner: 'Team Owner',
            communicationChannel: 'Communication Channel',
            actions: 'Actions',
            deleteTeam: 'Delete Team',
          },
          notificationRules: {
            name: 'Name',
            description: 'Description',
            triggers: 'When',
            properties: 'With',
            on_off: 'On/Off',
            actions: 'Actions',
            deleteRule: 'Delete Rule',
          },
          reportRules: {
            name: 'Name',
            type: 'Type',
            recipients: 'Recipients',
            frequency: 'Frequency',
            lastTimeSent: 'Last Time Sent',
            createdAt: 'Created At',
            deleteRule: 'Delete Rule',
          },
          riskCustomization: {
            serviceCriticality: 'Service Criticality',
            vulnerabilityIntelligence: 'Vulnerability Intelligence',
            serviceCriticalityDescription: 'The service business criticality',
            envType: 'Environment Type',
            envTypeDescription:
              'The resource environment type, Production Development etc.',
            complianceRequirements: 'Compliance Requirements',
            complianceRequirementsDescription:
              'Your services compliance requirements as configured in the service attributes',
            externalFacing: 'External Facing',
            externalFacingDescription:
              'According to your service risk attributes',
            sensitiveData: 'Sensitive Data',
            sensitiveDataDescription:
              'According to your service risk attributes',
            vulnerabilityIntelligenceDescription:
              'Opus vulnerability intelligence ranking',
            externalSource: 'External Sources',
            externalSourceDescription:
              'Use external resources within workflows  for better priotization',
            weightFactor: 'Weight Factor',
            successMessage: 'Risk factor updated successfully',
          },
        },
      },
      details: {
        addBusinessUnit: 'Add Service',
        editBusinessUnit: 'Edit Service',
        deleteBusinessUnit: 'Delete service',
        deleteBusinessUnitConfirm:
          'Are you sure you want to delete this service?',
        failedToDeleteBusinessUnit: 'Failed to delete service',
        failedToDeleteBusinessUnitDescription:
          'Service can only be deleted when there are no findings associated to it',
        businessUnit: 'Service',
        connectCloudAccount: 'Connect AWS Subscription',
        failedConnectionCloudEnv: 'Failed to connect to Cloud Environment!',
        createAWSStack: 'Create AWS stack to connect your AWS account',
        connectAzureSubscription: 'Connect Azure Subscription',
        connectCodeRepository: 'Connect Workspace',
        addWorkspace: 'Add Workspace',
        editWorkspace: 'Edit Workspace',
        accountName: 'Account Name',
        roleARN: 'Role ARN',
        externalID: 'External ID',
        addSla: 'Add SLA',
        addSlaPolicy: 'Add SLA Policy',
        addUsers: 'Add Users',
        editSla: 'Edit SLA',
        addTeam: 'Add team',
        editTeam: 'Edit team',
        addNewAutomationRule: 'Add New Automation Rule',
        addNewReportRule: 'Add Report',
        editAutomationRule: 'Edit Automation  Rule',
        editReportRule: 'Edit Report Rule',
        businessUnitName: 'Service Name',
        associatedRepository: 'Associated Repository',
        team: 'Associated Team',
        businessUnitOwner: 'Service Owner',
        businessCriticality: 'Service Criticality',
        environments: 'Environments',
        workspaceEnvironmentType: 'Workspace Environment Type',
        environmentType: 'Environment Type',
        assignedAccount: 'Assigned Account',
        sourceCodeType: 'Workspace Provider',
        workspaceAlias: 'Workspace Alias',
        workspaceId: 'Workspace ID',
        enterBusinessUnitName: 'Please enter service name',
        enterRepositoryUrl: 'Please enter repository url',
        enterOwnerEmail: 'Please enter owner email',
        enterEnvironmentType: 'Please enter environment type',
        enterTheAccountName: 'Please enter the account name',
        enterTheWorkspaceId: 'Please enter the workspace ID',
        severity: 'Severity',
        slaHours: 'SLA Hours',
        enterSlaInSeconds: 'Enter SLA in seconds',
        teamName: 'Team Name',
        teamOwner: 'Team Owner',
        communicationChannel: 'Communication Channel',
        communicationApp: 'Communication App',
        typeTeamName: 'Please type team name',
        typeTeamOwner: 'Please type team owner',
        typeCommunicationApp: 'Please select communication app',
        typeCommunicationChannel: 'Please type communication channel',
        areYouSureYouWantToDeleteApplication:
          'This action will delete {{param1}}. Are you sure?',
        areYouSure: 'Are you sure?',
        cloudConnectionDescription:
          'To create [stack]({{url}}) in aws account, make sure you are connected to the account you wish to connect to. Please take your Role ARN and External ID from the output section.',
        azureTenantIdTitle:
          '1. [Click here]({{url}}) to open Your Azure AD and Copy your Tenant ID',
        azureConnectOpusAppTitle:
          '2. [Click here]({{url}}) to connect Opus application to your Azure environment',
        azureGetAppObjIdTitle: '3. Get your Opus application object ID',
        azureGetAppObjIdText:
          'by using the portal [link]({{url}}) or running the following shell command',
        azureConnectSubscriptionTitle:
          '4. [Click here]({{url}}) to connect to your Azure subscription',
        azureConnectSubscriptionText:
          'Fill in the object ID in the arm template and take out your subscription ID',
        azureEnterSubscriptionTitle: 'Enter your Subscription ID here',
        azureEnterWorkspaceAliasTitle:
          'Enter a name for this cloud workspace (Alias)',
        requiredField: '{{param1}} is required',
        notificationRuleFormValidationErrorMessage:
          'Please fix all errors to save this notification rule',
        notificationRuleFormRequiredErrorMessage:
          'Please fill the mandatory fields',
        notificationRuleEnvironmentMismatchErrorMessage:
          'Please note: the following BU is not associated with your chosen environment. The filter is ineffective.',
        failedWorkspace: 'Failed to {{param1}}',
        failedWorkspaceMessage:
          'It was not possible to {{param1}} this workspace.',
        failedToDeleteWorkspace: 'Failed to delete workspace',
        failedToDeleteWorkspaceDescription: `Opus can't delete this workspace, it is linked to other assets in the system.`,
        assetGroups: 'Asset Groups',
        assetGroupsHelper: 'Use comma to separate multiple groups',
        resourceTagName: 'Resource Tag Name',
        resourceTagValue: 'Resource Tag Value',
        ownerTagsLabel: 'Owner Tags - For multiple add comma seperator',
        ownerTagsSectionTitle: 'Owner Tags',
        resourceTagsSectionTitle: 'Resource Tags',
        reportRuleDrawerTitle: 'Report Rule',
        reportRuleDrawerDescription:
          "Group your organization's resources to align with service goals",
        sendingReport: 'Sending report',
        sendingReportSuccess:
          'Report is being processed and will be sent shortly',
        sendingReportError: 'Failed to send report with error',
        complianceRequirements: 'Compliance Requirements',
        projectName: 'Project Name',
        addRiskCustomization: 'Add Risk Customization',
        disabledDefaultMessage:
          'The default configuration cannot be disabled or deleted.\n {{param}} ',
        riskDisabledDefaultMessage:
          'Click on the Edit button to edit the default Risk Customization configuration.',
        slaDisabledDefaultMessage:
          'Click on the Edit button to Add/Edit severities SLAs.',
        riskConfigDefaultValuesMessage:
          'The initial weight factors values are inherited from the default configuration.',
      },
      buttons: {
        labels: {
          aws: 'AWS',
          azure: 'Azure',
          workspace: 'Workspace',
        },
      },

      forms: {
        labels: {
          reportRules: {
            name: 'Name',
            description: 'Description',
            dashboardType: 'Dashboard Type',
            recipients: 'Recipients',
            type: 'Type',
            filter: 'Filter',
            scopes: 'Scopes',
            groups: 'Groups',
            services: 'Services',
            widgets: 'Widgets',
            frequency: 'Frequency',
            sendAnEmail: 'Send An Email',
            role: 'Role',
            timeFrame: 'Time Frame',
            trendMetricsTypes: 'Trend Metrics Types',
            domains: 'Domains',
          },
        },
      },
    },
    integrations: {
      deleteConnection: 'Delete Connection',
      modal: {
        description:
          'Build a resilient risk management program leveraging data-driven and risk-driven approach by adding contextual information to each security finding and help.',
        link: 'How to integrate with',
      },
      details: {
        openYourGcpConsole: 'Open your GCP console cloud shell',
        enableTheRelevantApis:
          'Enable the relevant APIs using the following shell command',
        gcpProjectId: 'Your GCP Project ID',
        createOpusServiceAccount:
          'Create Opus service account for the given project',
        makeSureToGrantViewer:
          'Make sure to grant Viewer, Security Reviewer and Storage Object Viewer permissions',
        serviceAccountJsonKey: 'Your Service account JSON key',
        awsDeployTeamOwnerInputName: 'Role ARN',
        awsDeployTeamOwnerInputPlaceholder: 'AWS Role ARN',
        awsDeployLinkDescription:
          'Ensure that you are logged in to your AWS account',
        awsMultiDeployLinkDescription:
          'Ensure that you are logged in to your Organization Management account',
        awsMultiDeployTeamOwnerInputName: 'Management Account Role ARN ',
        awsMultiDeployDescription:
          'Deploy a stack to your AWS Organization Management Account, this will create a StackSet that allows you to connect Opus to all of your AWS Sub Accounts.',
        awsMultiDeployCreateProcessTitle: 'Once the StackSet created',
        awsMultiDeployCreateProcessInstructionOne: `From the left menu choose StackSet and search for OpusReadRoleStackSet and select it`,
        awsMultiDeployCreateProcessInstructionTwo: `Choose Actions and “Add Stacks To StackSet”`,
        awsMultiDeployCreateProcessInstructionThree: `Decide to which Accounts you want to Deploy the stack for connecting Opus`,
        awsMultiDeployCreateProcessInstructionFour: `Choose your preferred region and deploy the stacks, wait for all the accounts deployment to finish`,
        awsMultiDeployCreateProcessInstructionFive: `Once done, take the Role ARN from the original stack deployed to the MGMT account, put it in Opus and click Connect.`,
      },
    },
    global: {
      severity: {
        lbi: 'LBI',
        mbi: 'MBI',
        hbi: 'HBI',
        low: 'Low',
        medium: 'Medium',
        high: 'High',
        critical: 'Critical',
        info: 'Info',
      },
      resources: {
        AwsS3Bucket: 'S3',
        AwsEc2SecurityGroup: 'Security Group',
        AwsEc2Instance: 'EC2 Instance',
        AwsRdsDbInstance: 'RDS',
        AwsIamRole: 'Role',
        AwsEcrContainerImage: 'ECR',
      },
      pagination: {
        results: 'Results: {{param1}} of {{param2}}',
        rowsPerPage: 'Rows per page',
        pageSize: 'Page Size',
      },
      icons: {
        expandMore: 'Expand More',
        defaultAvatar: 'Default Avatar',
        created: 'Created',
        lastUpdated: 'Last Updated',
      },
      sidebar: {
        titles: {
          dashboards: 'Dashboards',
          findings: 'Findings',
          risks: 'Risks',
          campaigns: 'Campaigns',
          flows: 'Flows',
          assets: 'Assets',
          connections: 'Connections',
          automationManagement: 'Automation Management',
          guardrails: 'Guardrails',
          modules: 'Modules',
          risk: 'Risk',
          comparative: 'Comparative',
          user_management: 'Settings',
          integrations: 'Integrations',
          findingSource: 'Findings Fource',
          settings: 'Settings',
          logout: 'Logout',
          contactUs: 'Contact Us',
          documentation: 'Documentation',
          help: 'Help',
          userManagement: 'User Management',
          organization: 'Organization',
          resources: 'Resources',
        },
        email: {
          subject: 'contact request from <<user>> at <<company>>',
          body: '',
        },
      },
    },
    labels: {
      title: 'Labels',
      newLabel: 'New Label',
      saving: 'Saving labels...',
      successfulSave: 'Labels saved successfully',
      unsuccessfulSave: 'There was a problem saving your label selections',
      addLabels: 'Add Labels',
      pleaseSelectLabels: 'Please select labels',
    },
  },
};

export default en;
