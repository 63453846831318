import { AuthorizedContent } from '@frontegg/react';
import { Box, Button } from '@mui/material';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import moment from 'moment';
import { ApplicationLogo } from 'Application/interfaces/item';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';

interface ConnectionListColumnsProps {
  t: any;
  useDeleteMutation: UseMutation<any>;
  onDelete: (decision: boolean, deleteResponse?: any) => void;
  requiredPermissions: ApplicationPermission[] | undefined;
  logos?: ApplicationLogo[];
  setShouldIgnoreRowClick?: any;
  onUpdate?: (updateBody: any) => void;
  isUpdating?: boolean;
  columns: any;
}
interface ActionColumn extends ConnectionListColumnsProps {
  title: string;
  message: string;
  paramKey?: string;
}

export function getActionColumn({
  t,
  useDeleteMutation,
  title,
  onDelete,
  onUpdate,
  requiredPermissions,
  message,
  paramKey,
}: ActionColumn) {
  return [
    {
      field: 'action',
      headerName: '',
      sortable: false,
      align: 'right',
      initialFlex: 1,
      cellClass: 'action-column',
      headerClass: 'column-disable-sort',
      cellRenderer: (params: any) => {
        return (
          <div className="data-grid-action-button-container">
            <AuthorizedContent
              requiredPermissions={requiredPermissions}
              render={(isAuthorized) => (
                <Button
                  onClick={() => {
                    onUpdate && onUpdate(params.data?.id);
                  }}
                  className="data-grid-action-button"
                  disabled={!isAuthorized}
                >
                  <OpusSvgIcon type={SVG_ICON_TYPES.EDIT_ICON} />
                </Button>
              )}
            ></AuthorizedContent>
            <AuthorizedContent
              requiredPermissions={requiredPermissions}
              render={(isAuthorized) => (
                <DeleteWithConfirm
                  id={params?.data?.id}
                  setShouldIgnoreRowClick={null}
                  useMutation={useDeleteMutation}
                  title={title}
                  message={t(message, {
                    param1: paramKey ? params?.data[paramKey] : '',
                  })}
                  callback={onDelete}
                  disabled={!isAuthorized}
                >
                  <Button
                    className="data-grid-action-button"
                    disabled={!isAuthorized}
                  >
                    <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED} />
                  </Button>
                </DeleteWithConfirm>
              )}
            ></AuthorizedContent>
          </div>
        );
      },
    },
  ];
}

class ConnectionsListGridColumns {
  static getConnectionListColumns({
    t,
    useDeleteMutation,
    onDelete,
    onUpdate,
    requiredPermissions,
    setShouldIgnoreRowClick,
    columns,
  }: ConnectionListColumnsProps) {
    const actionColumn = getActionColumn({
      t,
      useDeleteMutation,
      title: t('integrations.deleteConnection'),
      onDelete,
      onUpdate,
      requiredPermissions,
      setShouldIgnoreRowClick,
      columns,

      message: 'settings.details.areYouSureYouWantToDeleteApplication',
      paramKey: 'name',
    });

    const dynamicColumns = Object.keys(columns).map((columnKey) => {
      const cellRenderer = (params: any) => {
        if (columnKey === 'name' && params.data.isFallback) {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <OpusSvgIcon type={SVG_ICON_TYPES.STAR_ICON} />
              {params.data[columnKey]}
            </Box>
          );
        }
        if (Array.isArray(params.data[columnKey])) {
          const joinedValue = params.data[columnKey]
            .map((item: any) => item.toString())
            .join(' ');
          return (
            <TextOverflow
              type={OverflowTextDisplay.ELLIPSIS}
              maxWidth={160}
              direction={OverflowDirection.WIDTH}
            >
              {joinedValue}
            </TextOverflow>
          );
        }
        if (
          typeof params.data[columnKey] === 'string' &&
          moment(params.data[columnKey], moment.ISO_8601).isValid()
        ) {
          const formattedDate = moment(params.data[columnKey]).format(
            '  MMM DD, YYYY [at] HH:mmA'
          );
          return formattedDate;
        }
        return params.data[columnKey];
      };

      return {
        headerName: columns[columnKey],
        field: columnKey,
        sortable: true,
        cellRenderer,
        initialFlex: 1,
      };
    });

    return [...dynamicColumns, ...actionColumn] as Array<ColDef | ColGroupDef>;
  }
}

export default ConnectionsListGridColumns;
